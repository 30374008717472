<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col sm="12" md="3" lg="4">
          <h3 class="mb-0 header-text">Clients</h3>
        </b-col>
        <b-col cols="6" md="1" lg="4"></b-col>
        <b-col sm="8" md="8" lg="4">
          <b-form-group
            id="search-wrapper"
            label-for="input-1"
            v-bind:class="{ active: isActive, focused: isFocused }"
          >
            <b-form-input
              id="filter-input"
              ref="filterinput"
              type="text"
              placeholder="Search clients"
              v-model="query"
              @keyup="filterTableBySearch"
              @blur.native="removeCancelSearchIcon"
              @focus="focused"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>

    <!-- Table -->
    <el-table
      id="clientsTable"
      class="table-responsive table"
      :data="displayData"
      header-row-class-name="thead-light"
      v-if="!isLoading"
      @sort-change="sortChange"
    >
      <el-table-column
        label="Tavern name"
        min-width="90px"
        prop="businessName"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.businessName }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="Client"
        min-width="90px"
        prop="firstName"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">
            {{ row.firstName }} {{ row.lastName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Total loans"
        min-width="90px"
        prop="totalLoans"
        sortable
      >
      </el-table-column>

      <el-table-column
        label="Total loans value"
        min-width="90px"
        :fields="fields"
        :formatter="currencyFormatter"
        prop="totalLoanValue"
        sortable
      >
      </el-table-column>

      <el-table-column
        label="Repayment rate"
        min-width="90px"
        :formatter="percentageFormatter"
        prop="repaymentRate"
        sortable
      >
      </el-table-column>
      <el-table-column label="" min-width="35px">
        <template v-slot="{ row }">
          <div class="row-buttons">
            <button
              v-if="row.cardTagHash != ''"
              class="btn btn-sm btn-primary"
              :data-id="row.Id"
              @click="
                showUnlinkModal(
                  row.firstName + ' ' + row.lastName,
                  row.businessName,
                  row.Id
                )
              "
            >
              <span class="button-text">Unlink</span>
              <span class="button-icon"><i class="fas fa-unlink"></i></span>
            </button>
            <button
              class="btn btn-sm btn-primary"
              @click="showSyncModal(row.businessName, row.depositAccountId)"
            >
              <span class="button-text">Balance</span>
              <span class="button-icon"
                ><i class="fas fa-dollar-sign"></i
              ></span>
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      :total="rows"
      :page-size="pageSize"
      align="right"
      v-if="!isLoading"
    >
    </el-pagination>

    <!-- Unlink confirmation modal -->
    <modal :show="isModalVisible" @close="closeModal">
      <div class="form">
        <p style="font-weight: 700">
          Are you sure you want to remove the card from
          <span style="color: #f7941d"
            >{{ clientName }} / {{ businessName }}</span
          >
        </p>
        <div>
          <div class="message" v-if="cards.length == 0 && !loading">
            <div class="message-icon" v-if="loading">
              <i class="fas fa-exclamation"></i>
            </div>
            <span class="">No cards found.</span>
          </div>
          <div class="success-message" v-if="!loading"></div>
          <spinner v-if="loading"></spinner>
        </div>
        <b-button
          @click="unlinkClientCard(cards[0].Id, cards[0].clientId)"
          type="submit"
          variant="primary"
          v-if="cards.length != 0 && !loading"
          >Confirm</b-button
        >
        <b-button @click="closeModal()" type="submit" variant="primary cancel"
          >Cancel</b-button
        >
      </div>
    </modal>

    <!-- Sync modal -->
    <modal :show="isSyncModalVisible">
      <div>
        <div class="form" v-if="!syncLoading">
          <p class="text-center" style="font-weight: 700">
            Tavern Wallet balance for
            <span style="color: #f7941d">{{ businessName }}</span>
            <span style="color: #f7941d" v-if="businessName == ''"
              >Unknown</span
            >
            is
          </p>
          <p class="text-center" style="font-weight: 700">
            <span style="color: #f7941d">{{ formattedBalance }}</span>
          </p>
          <p class="text-center" style="font-weight: 600">
            Do you want to update?
          </p>
          <div class="col-md-12 text-center">
            <b-button @click="updateWallet()" type="submit" variant="primary"
              >Update</b-button
            >
            <b-button
              @click="closeModal()"
              type="submit"
              variant="primary cancel"
              >Cancel
            </b-button>
          </div>
        </div>
        <div v-if="syncLoading">
          <p class="text-center" style="font-weight: 700">
            Fetching Tavern Wallet balance for
            <span style="color: #f7941d">{{ businessName }}</span>
            <span style="color: #f7941d" v-if="businessName == ''"
              >Unknown</span
            >
          </p>
          <spinner v-if="syncLoading"></spinner>
          <div class="col-md-12 text-center">
            <b-button
              @click="closeModal()"
              type="submit"
              variant="primary cancel"
              >Cancel
            </b-button>
          </div>
        </div>
      </div>
    </modal>

    <!-- Sync confirmation modal -->
    <modal :show="isConfirmationVisible" @close="closeModal">
      <div class="form cta">
        <div class="">
          <div class="success-message" v-if="syncSuccess">
            <div class="icon-wrapper">
              <i class="fas fa-check"></i>
            </div>
            <p>Client balances have been resynced.</p>
          </div>
          <div class="success-message error" v-if="!syncSuccess">
            <div class="message-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </modal>
  </b-card>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Pagination,
} from "element-ui";
import config from "../../config";
import Spinner from "../../components/Spinner.vue";
import axios from "axios";
export default {
  name: "clients-table",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Pagination.name]: Pagination,
    Spinner,
  },
  data() {
    return {
      isActive: false,
      isFocused: false,
      currentPage: 1,
      pageSize: 10,
      isLoading: false,
      syncLoading: false,
      sortValue: "",
      businessName: "",
      businessId: "",
      clientId: "",
      depositAccountId: "",
      walletBalance: 0.0,
      formattedBalance: "",
      isModalVisible: false,
      isSyncModalVisible: false,
      isConfirmationVisible: false,
      clientName: "",
      syncSuccess: null,
      errorMessage: "",
      query: "",
      cards: [],
      masterData: [],
      tableData: [
        {
          businessName: "Loading ....",
          firstName: "",
          lastName: "",
          totalLoans: "0",
          totalLoanValue: "0",
          repaymentRate: "100",
        },
      ],
      fields: [
        {
          key: "totalLoanValue",
          label: "value(R$)",
          sortable: true,
          formatter: (value, key, item) => {
            value.toLocaleString("en-ZA", { style: "currency", currency: "R" });
          },
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
    displayData() {
      console.log("getting display data");
      if (!this.tableData || this.tableData.length === 0) return [];
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
    loading() {
      return this.$store.getters["clients/isLoading"];
    },
    success() {
      return this.$store.getters["clients/success"];
    },
  },
  methods: {
    sortChange($event) {
      // Get sort change data
      const { prop, order } = $event;
      console.log($event);
      // Check for sort order and table data accordingly
      if (order == "ascending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] > b[prop] ? 1 : -1
        );
      }

      if (order == "descending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] < b[prop] ? 1 : -1
        );
      }
      console.log("Table data:", this.tableData);
    },
    filterTableBySearch() {
      // Get all clients from store
      const clients = this.masterData;
      // Filter clients by search query
      const filterdClients = clients.filter(
        (c) =>
          c.businessName.toLowerCase().includes(this.query.toLowerCase()) ||
          c.firstName.toLowerCase().includes(this.query.toLowerCase()) ||
          c.lastName.toLowerCase().includes(this.query.toLowerCase())
      );
      // Store the filtered data as the new tatble data values
      this.tableData = filterdClients;
    },
    resetTableData() {
      // When query length is zero input is in empty state, we get client from store
      const clients = this.$store.getters["clients/clients"];
      // Store clients from store and update table data
      this.tableData = clients;
    },
    cancelFilterBySearch() {
      // Get the search input and set it to null/empty
      const input = (this.$refs.filterinput.$el.value = "");
      // Remove focused class
      this.isFocused = false;
      // Remove active class
      this.isActive = false;
      // Reset table data
      this.resetTableData();
    },
    removeCancelSearchIcon($event) {
      // Get typed in value in input
      const queryLength = $event.target.value.trimStart().length;
      // Remove cancel icons based on length value length
      if (queryLength == 0) {
        // Reverse is active
        this.isActive = false;
        // Reverse is focused
        this.isFocused = false;
      }
    },
    focused() {
      // Set focused class on search input wrapper
      this.isFocused = true;
    },
    async showUnlinkModal(fullName, businessName, Id) {
      // console.log(this.$store.getters["clients/loading"]);
      // Store business name and id
      this.clientName = fullName;
      this.businessName = businessName;
      this.businessId = Id;
      // Open unlink modal here
      this.isModalVisible = true;

      // Dispatch action to get all business linked cards
      const result = await this.$store.dispatch(
        "clients/getAllClientCards",
        this.businessId
      );
      console.log("CLIENT CARDS", result.data);
      this.cards = result.data;
    },
    async showSyncModal(businessName, depositAccountId) {
      // Open sync modal here
      this.syncLoading = true;
      this.isSyncModalVisible = true;
      // Store data
      this.businessName = businessName;
      this.depositAccountId = depositAccountId;
      var http = axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] },
      });
      http
        .get(`client/depositAccount/${this.depositAccountId}`)
        .then((result) => {
          this.walletBalance = result.data.balances.availableBalance;
          this.formattedBalance = this.formatCurrency(this.walletBalance);
          this.syncLoading = false;
        });
    },
    async updateWallet() {
      var http = axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] },
      });

      http
        .post("client/updateBalance", {
          depositAccountId: this.depositAccountId,
          walletBalance: this.walletBalance,
        })
        .then(() => {
          this.isSyncModalVisible = false;
          this.syncSuccess = true;
          this.isConfirmationVisible = true;
          setTimeout(() => {
            this.closeModal();
          }, 2000);
        })
        .catch((err) => {
          this.isSyncModalVisible = false;
          this.syncSuccess = false;
          this.isConfirmationVisible = true;
          setTimeout(() => {
            this.closeModal();
          }, 2000);
        });
    },
    closeModal() {
      // Open unlink modal here
      this.isModalVisible = false;
      this.isSyncModalVisible = false;
      this.isConfirmationVisible = false;
    },
    async unlinkClientCard(Id, clientId) {
      // Card data to pass to endpoint
      const card = {
        Id,
        clientId,
      };
      // Dispatch action to unlink card with id
      this.$store.dispatch("clients/unlinkClientCard", card);
      this.closeModal();
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].Id == card.clientId) {
          this.tableData[i].cardTagHash = "";
        }
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    currencyFormatter(row, column, cellValue) {
      return this.formatCurrency(cellValue);
    },
    formatCurrency(cellValue) {
      return cellValue != null
        ? parseFloat(cellValue).toLocaleString("en-ZA", {
            style: "currency",
            currency: "ZAR",
          })
        : (0).toLocaleString("en-ZA", { style: "currency", currency: "ZAR" });
    },
    percentageFormatter(row, column, cellValue) {
      return this.formatPercentage(cellValue);
    },
    formatPercentage(cellValue) {
      return (
        (isNaN(parseFloat(cellValue)) ? 0 : parseFloat(cellValue).toFixed(2)) +
        " %"
      );
    },
    async syncModal(clientId) {
      // Set client id
      this.clientId = clientId;
      // Dispatch action to start sync
      const result = await this.$store.dispatch(
        "clients/syncClientById",
        this.clientId
      );
      // Do API call here, on success show modal
      if (result && result.data == "Ok") {
        // Show modal here
        this.isSyncModalVisible = true;
        // Set sync state data property
        this.syncSuccess = true;
        // Close modal
        setTimeout(() => {
          this.closeModal();
        }, 3000);
      }

      // Handling account error
      if (result && result.data == "INVALID_DEPOSIT_ACCOUNT_ID") {
        // Show modal here
        this.isSyncModalVisible = true;
        // Set sync state data property
        this.syncSuccess = false;
        // Error message
        this.errorMessage = "Invalid deposit account ID.";
        // Close modal
        setTimeout(() => {
          this.closeModal();
        }, 4000);
      }
      // Handle timeour Error from API
      if (result && result.code == "ECONNABORTED") {
        // Show modal here
        this.isSyncModalVisible = true;
        // Set sync state data property
        this.syncSuccess = false;
        // Error message
        this.errorMessage =
          "There was an error(s) with your request. Please try again.";
        // Close modal
        setTimeout(() => {
          this.closeModal();
        }, 4000);
      }
    },
  },
  mounted() {
    this.isLoading = true;
    var http = this.axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: this.$store.getters["accounts/token"] },
    });
    http.get("/client/stats").then((response) => {
      // Store retrieved clients in table data
      console.log(response);
      this.tableData = response.data;
      this.masterData = response.data;
      // Store retrived clients in store
      this.$store.dispatch("clients/addClients", response.data);
      this.handleCurrentChange(1);
      this.isLoading = false;
    });
  },
};
</script>

<style scoped>
#search-wrapper {
  position: relative;
  margin: 0;
}

.cta {
  text-align: center;
}

#search-wrapper.focused input {
  border-color: #f7941d;
}

#search-wrapper.active .clear-search {
  display: flex;
}

#search-wrapper .clear-search {
  padding-top: 2px;
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}
#search-wrapper .clear-search i {
  font-size: 13px;
  line-height: none;
  padding: 0;
  margin: 0;
  color: #525f7f;
}

.devices {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  list-style: none;
  padding: 0 5px;
  border-bottom: 1px solid #ebeef5;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 35px;
}

.devices .device {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ebeef5;
  padding: 10px 0;
}

.devices .device button {
  color: #fff;
  background-color: #f1f1f1;
  border-color: #f7941d;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.devices .device button i {
  color: #f7941d;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
}

.message-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 10px auto;
  margin-bottom: 10px;
}

.message.delete {
  margin-top: 10px;
  margin-bottom: 10px;
}

.message.delete .message-icon {
  background: #f5f5f5;
}

.message.delete .message-icon i {
  color: #f7941d;
  margin-top: -4px;
}

.message-icon i {
  font-size: 30px;
}

.message span {
  color: #525f7f;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.success-message {
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.success-message p {
  color: #f7941d;
  margin-top: 15px;
}
.icon-wrapper {
  width: 60px;
  height: 60px;
  background: #f7941d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5px;
}

.icon-wrapper i {
  font-size: 30px;
  color: #fff;
}
</style>
